$primary-color: #333

body
  color: $primary-color

h1
  font-size: 18px

.listing
  display: flex
  flex-direction: column

.listingImg
  width: 300px

.scootLink
  width: 100%
  display: flex
  justify-content: center
