p {
  line-height: 1.5em;
}

.mainMobileHeader {
  font-weight: bold;
  font-size: 1.5em;
}

.mainMobileHeader p {
  line-height: 1em;
}

.inventoryList {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.nowrap {
  white-space: nowrap;
}

.inventoryList .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.inventoryList .row .listing {
margin: 6px;
  min-width: 300px;
  flex: 0 0 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 340px;
  background: #fff7e6;
  border-radius: 12px;
  align-items: center;
}

.inventoryList .row .listing:hover, .inventoryList .row .listing:hover a  {
  background: #e9fcfd;
  color: #00c8ce;
}


.mainContent {
  top: 120px;
  position: relative;
  margin-bottom: 40px;
}

.inventoryList .row .listing:hover {
  cursor: pointer;
}

.flexit {
  display: flex;
  align-items: center;
}


.homePage {
  width: 80%;
  margin: 0 auto;
}
.mopedPage {
  width: 50vw;
  margin: 3vh auto;
}

.aboutPage {
  width: 80%;
  margin: 24px auto;
}

@media (max-width: 800px) {

  .inventoryList .row {
    flex-direction: column;
    align-items: center;
  }

}

.mainMobileHeader {
  display: none;
}


@media (max-width: 500px) {

  .homePage {
    order:99;
    width: 100%;
  }
  .navHeader {
    height: 100px;
    display: block;
  }

  .deets {
    display: none;
  }

  .mainMobileHeader {
    display: block;
    width: 100%;
    text-align: center;
  }

  .mopedPage {
    width: 75vw;
    margin: 1vh auto;
  }

  .mainContent {
    top: 80px;
  }

  .galleryWrap {
    margin-top: 100px;
  }

}
