.scooterPage
  display: flex
  justify-content: center
  margin-top: 25px
  margin-bottom: 25px
  position: relativegit
  @media (max-width: 800px)
    top: 120px

.mobileHeader
  width: 100%
  border-bottom: 1px solid #cccccc
  background-color: #ffffff
  z-index: 888
  position: fixed
  top: 80px
  .scooterColors
    justify-content: center
    padding-bottom: 20px
    p
      height: 52px
      width: 52px
      margin: 0 6px
  h1, p
    margin: 0
    font-size: 24px
  .flex
    display: flex
    justify-content: space-between
    div
      padding: 12px
  @media (min-width: 800px)
    display: none

.herokart-wrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  .text-wrapper
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 24px
    border: 3px solid #00c7cd
    padding: 24px
    background-color: #ffdbb8
  h1
    font-size: 24px
    margin: 0
    padding: 0
  p
    width: 80%
    font-weight: bold
  .primary-button
    display: inline-block
    padding: 12px 24px
    border-radius: 6px
    background-color: #fd8b11
    color: white
    font-size: 16px
    font-weight: 500
    text-align: center
    text-decoration: none
    border: none
    cursor: pointer
    transition: all 0.5s ease

    &:hover
      background-color: #00c7cd
      box-shadow: 0 6px 12px rgba(74, 144, 226, 0.2)

    &:focus
      outline: none
      box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.3)


.herokart
  width: 80%
  top: 0
  left: 0
  z-index: -1
  border-radius: 24px
  margin-top: 24px
  margin-bottom: 24px
  @media (max-width: 800px)


.galleryWrap
  width: 580px
  height: 370px
  margin: 80px 12px 0
  background-color: #cccccc
  @media (max-width: 500px)
    margin-top: 120px

.galleryWrap img
  width: 580px

.details
  display: flex
  flex-direction: column
  width: 400px
  margin: 0 12px 40px
  .detailHeader
    @media (max-width: 800px)
      display: none !important
    display: flex
    flex-direction: column
    .flex
      display: flex
      flex-direction: row
      .rightTxt
        text-align: right
    div:last-child
      margin-left: auto
    h1
      margin: 0
      font-size: 24px
  p
    display: flex
    justify-content: space-between
    padding: 7px
    margin: 0
  .productDetails p:nth-child(even)
    background-color: #efefef

.description
  background-color: #efefef
  border-radius: 24px
  padding: 12px
  margin: 24px 0
  font-weight: bold

.interest
  background-color: #efefef
  border-radius: 24px
  text-align: center
  margin-top: 24px


.scooterColors
  display: flex
  margin: 6px 0 0 0
  flex-direction: row !important
  p
    height: 39px
    width: 39px
    border-radius: 100%
    border: 1px solid #cccccc
    margin: 3px 5px
    padding: 0
    cursor: pointer
  .white
    background-color: #ffffff
  .black
    background-color: #000000
  .blue
    background-color: #012590
  .red
    background-color: #9f0e0c
  .gunmetal
    background-color: #878787
  .green
    background-color: #33b635
  .yellow
    background-color: #fcd517
  .babyblue
    background-color: #5e9fd1
  .orange
    background-color: #ff4f24
  .pink
    background-color: #f987b2
  .teal
    background-color: #68c6c8
  .midnightblue
    background-color: #212958
  .silver
    background-color: #cccccc




.scooterPage
  @media screen and (max-width: 800px)
   flex-direction: column
   align-items: center
   .details
     .detailHeader
       display: flex
       flex-direction: column

     @media screen and (max-width: 800px)
     width: 80vw
   .galleryWrap
    @media screen and (max-width: 800px)
      background-color: transparent
      height: auto
      width: auto
      img
       @media screen and (max-width: 800px)
        width: 85vw
      .gallery
       @media screen and (max-width: 800px)
        display: flex
        flex-direction: column
        align-items: center



