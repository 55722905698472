.navHeader {
    height: 120px;
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 0 12px;
    border-bottom:1px solid #cccccc;
    position: fixed;
    width: 100%;
    background-color: #ffffff;
    top: 0;
    z-index: 999;
    flex-direction: column;
}

.about {
    margin-right: 25px;
    margin-left: 25px;
}

.test {
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-family: 'Orelega One', cursive;
}

.c1 {
    color: #00c8ce;
}

.c2 {
    color: #ff8c11;
}

link, a {
    text-decoration: none;
    color: #ff8c11;
}

link:hover, a:hover {
    text-decoration: underline;
    color: #00c8ce;
}

.deets {
    margin-left: 12px;
}


.flexit {
    justify-content: space-between;
    flex: 1;
    width: 100%;
}

.rightNav {
    display: flex;
}

.rightNav a {
    margin-left: 12px;
}


.detailWrapper h2, .detailWrapper h3 {
    margin: 0;
}

.detailWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
}



@media (max-width: 500px) {

    .detailWrapper {
        display: none;
    }

    .navHeader {
        height: 80px;
    }

    .flexit {
display: flex;
        flex-direction: column;
    }

    .rightNav .about {
        margin-right: 0px;
    }
}
